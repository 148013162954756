/*-----------------------------------------------
|   Scrollbar style for windows
-----------------------------------------------*/
.windows{
  &.chrome{
    .scrollbar{
      overflow: auto;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        transition: all 5s ease;
        border-radius: 4px;
        background-color: rgba($gray-600, 0.5);
      }
    }
  }
  &.firefox{
    .scrollbar{
      scrollbar-color: rgba($gray-600, 0.5) transparent;
      scrollbar-width: thin;
    }
  }
}