/*-----------------------------------------------
|   Form
-----------------------------------------------*/
label{
  font-size: map_get($font-sizes, '-1');
  font-weight: $font-weight-bold;
  letter-spacing: 0.02em;
}

.custom-control-label{
  line-height: 1.45rem;
}
input:-internal-autofill-selected{
  background-color: #000 !important;
  color: #fff !important;
}