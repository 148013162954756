/*-----------------------------------------------
|   Navigation Top
-----------------------------------------------*/

.dropdown-menu-card{
  padding: 0;
  .card{ border: 0}
}

.navbar{
  .dropdown-menu {
    overflow: visible;
    left: -0.5625rem;
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 1rem;
      height: 1rem;
      background: $black;
      top: -0.375rem;
      left: map_get($spacers, 4);
      transform: rotate(45deg);
      background: inherit;
      border-radius: 0.125rem;
      border-width: 1px 0 0 1px;
      border-style: solid;
      border-color: $dropdown-border-color;
      box-shadow: $box-shadow-sm;
    }
    &.dropdown-menu-right {
      left: auto;
      right: -0.5625rem;
      &:after {
        right: 0.95rem;
        left: auto;
      }
    }
  }

  // Initial hover color for all navbar dropdown item
  .dropdown-item{
    font-weight: $font-weight-medium;
    @include hover-focus{
      background-color: initial;
      color: $gray-1000;
    }
  }
}
.navbar-toggler{
  border: 0;
  padding: 0;
}
.card-notification{
  min-width: 20rem;
  @include media-breakpoint-only(xs){ min-width: 16rem; }
}

/*-----------------------------------------------
|   Navbar Standard
-----------------------------------------------*/
.navbar-standard{
  @include media-breakpoint-down(md){
    .navbar-collapse{
      overflow-y: auto;
      max-height: calc(100vh - 57px);
    }
    .nav-item{ width: 100%; }
    .dropdown-menu{ &:after{ display: none; } }
  }
  
  @include media-breakpoint-up(lg){
    .navbar-card-components{ min-width: 38.13rem; }
    .navbar-card-login { min-width: 22rem; }
  }
}
