// pow() - Raise number to the nth power
//
// @param {number} $base      The base number
// @param {number} $exponents The exponent to which to raise $base
@function pow($base, $exponents) {
  $raised: 1;
  @for $i from 1 through $exponents {
    $raised: $raised * $base;
  }
  @return $raised;
}
@function toRem($px) { @return ($px / 16); }