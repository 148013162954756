/*-----------------------------------------------
|   Documentation link and Prism
-----------------------------------------------*/
.code-block{
  padding: 0;
  overflow: auto;
  pre[class*='language-']{
    padding: 1.6rem;
    border: 0;
    margin: 0;
    border-radius: 0;
  }
}

:not(pre) > code[class*='language-'], pre[class*='language-'] {
  background-color: $gray-1000;
  border: $border-width solid $gray-200;
  border-radius: $border-radius;
}
code.language-html:first-child{
  display: block;
}

code[class*='language-'], pre[class*='language-'] {
  color: $gray-300;
  font-family: $font-family-monospace;
}
pre[class*="language-"]{
  margin: 0;
}
.contains-anchor{
  a{
    opacity: 0;
    transition-property: opacity;
  }
  @include hover-focus{
    a{
      opacity: 1;
      text-decoration: none;
    }
  }
}
.token.property, .token.tag, .token.constant, .token.symbol, .token.deleted{
  color: $danger;
}
.token.punctuation{
  color: $gray-400;
}
.components-nav{
  font-family: $font-family-sans-serif;
  font-size: 0.85rem;
}

/*-----------------------------------------------
|   Component examples
-----------------------------------------------*/
/*-----------------------------------------------
|   Borders
-----------------------------------------------*/
.component-example{
  font-family: $font-family-sans-serif;
  [class^='border']{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 8rem;
    margin: .75rem;
    background-color: $gray-100;
  }
}


/*-----------------------------------------------
|   Border Components
-----------------------------------------------*/

.border-component{

  [class^=border],
  [class^="rounded-"]{
    display: inline-block;
    width: 5rem;
    height: 5rem;
    margin: .25rem;
  }

  [class^=border] {
    background-color: map-get($grays, '200' );
  }

  [class^="rounded-"] {
    background-color: map-get($grays, '900');
  }
}

/*-----------------------------------------------
|   Grid Components
-----------------------------------------------*/
.grid-component [class^=col]{
  border: 1px solid map-get($grays, '400');
  text-align: center;
  margin-bottom: 10px;
  color: map-get($grays, '700');
  padding: 0.5rem;
  font-size: map-get($font-sizes, '-1' );
}